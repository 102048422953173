import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

export default function ThankYou() {
  return (
    <>
      <Helmet>
        <title>Sixvees | Be Seen. Build a Website.</title>
        <meta
          name="description"
          content="Let Sixvees build you a website that will make a positive first impression, and put yourself a step ahead of your competition."
        />
      </Helmet>

      <Layout>
        <section className="page-section container text-center">
          <h1 className="pt-5">Thank you</h1>
          <div className="alert alert-success mt-4">
            Your message has been sent to our email. We will be in touch soon.
          </div>
        </section>
      </Layout>
    </>
  );
}
